import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './news-post.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import Breadcrumb from 'components/Breadcrumb'
import ContentfulRichTextPost from 'components/ContentfulRichTextPost'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const post = data[`post_${locale}`]
  const page = data[`page_${locale}`].nodes[0]
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
      link: '/news/',
    },
    {
      text: post.title,
    },
  ]
  const postContent = post.post

  const seoTitle = `${page.title} - ${post.title}`
  // const firstBlock = post.post.raw.content.filter(
  //   (row) => row.nodeType === 'paragraph'
  // )[0]
  // const seoDescription = firstBlock.content[0].value

  return (
    <Layout>
      <div>
        <Seo
          pageTitle={seoTitle}
          //  description={seoDescription}
        />
        <Banner image={page.banner} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <Inview>
              <div className={`${styles.intro} fade-in-up`}>
                <h1>{post.title}</h1>
                <h4>{post.postDate}</h4>
              </div>
            </Inview>
            <div className={styles.post}>
              <ContentfulRichTextPost content={postContent} />
              <Inview>
                <BgLink
                  isFadeInUp={true}
                  to='/news/'
                  text={`« ${tr('BACK_TO_ALL', locale)}`}
                />
              </Inview>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    post_en: contentfulNewsPost(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
    ) {
      title
      postDate(formatString: "YYYY-M-DD")
      post {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            ...GatsbyImage
          }
        }
      }
    }
    page_en: allContentfulNewsPage(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
      }
    }
    post_zh: contentfulNewsPost(
      slug: { eq: $slug }
      node_locale: { eq: "zh-CN" }
    ) {
      title
      postDate(formatString: "YYYY-M-DD")
      post {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            ...GatsbyImage
          }
        }
      }
    }
    page_zh: allContentfulNewsPage(filter: { node_locale: { eq: "zh-CN" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
      }
    }
  }
`
